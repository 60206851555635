<template>
    <div>
         <v-tooltip bottom max-width="240" v-for="btn in buttons" :key="btn.icon" open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="ml-4" active-class="active" color="primary" :to="{params: current.path, name:btn.component}" v-bind="attrs" v-on="on">
                        <v-icon>{{ btn.icon }}</v-icon>
                    </v-btn>
              </template>
            <h3 class="mt-2 mb-1">{{ btn.title }}</h3>
            <p>{{ btn.description }}</p>
         </v-tooltip>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   data(){
    return {
        buttons: [{
            icon: "mdi-chart-timeline-variant-shimmer",
            title: "Gesamtentwicklung Forecast",
            description: "alle Entwicklungen innerhalb eines Forecasts verfolgen",
            component: "Progress"
        },
        {
            icon: "mdi-ab-testing",
            title: "Forecast-Vergleich",
            description: "zwei Snapshots miteinander vergleichen - auch FC-übergreifend",
            component: "Compare"
        },
        {
            icon: "mdi-database-search",
            title: "Forecast-Explorer",
            description: "Daten anhand von Filtern zu einem bestimmten Zeitpunkt aufrufen",
            component: "Explorer"
        }]
    }
   },
   computed: {
    ...mapGetters(["current"])
   },
   methods: {
    goto(path){
        this.$router.push(this.current.path + path);
    }
   },
   created(){
     if (this.$store.getters.current.permissions.FC_TENANT_WRITE) {
        this.buttons.push({
            icon: "mdi-calendar-multiple",
            title: "Forecast-Verwaltung",
            description: "",
            component: "Forecasts",
            description: "Snapshots anlegen, schließen und Abgabe-Status der Units im Auge behalten"
        })
     }
   }
}
</script>

<style>

</style>